.Footer {
	margin-top: 50px;
	background-color: #000;
	margin-bottom: 0;
	padding-left: 20vw;
	padding-right: 20vw;
}

.Address {
	color: #fff;
}

.Address p a {
	color: #fff;
}

.Address p {
	font-size: 0.9em;
	margin: 0;
	padding: 30px 0;
}