.Navigation {
	width: 100%;
	height: 50px;
	padding: 20px 0 10px 0;
}

.brand {
	font-size: 2em;
	font-weight: 500;
	margin-right: 50px;
}

.navElement {
	width: 50px;
	font-size: 1.2em;
	transition: all ease-in-out .2s;
	border-bottom: 2px solid #fff;
	padding: 10px 20px 3px 20px;
}

.navElement:hover {
	border-bottom: 2px solid #000;
}

.mobileNav {
	display: none;
}

.mobileNavOpened {
	width: 100vw;
	height: 100vh;
	position: fixed;
	background-color: #fff;
	left: 0vw;
	padding-left: 20vw;
	padding-top: 50px;
	z-index: 1000;
}

.navStrokeTop, .navStrokeMiddle, .navStrokeBottom {
	width: 20px;
	border-bottom: 2px solid #000;
	display: block;
	color: #fff;
	font-size: 0.1em;
	transition: all ease-in-out .1s;
	cursor: pointer;
	position: relative;

}

.selected .navStrokeTop {
	transform: rotate(45deg);
	transform-origin: right center;
}

.selected .navStrokeMiddle {
	display: none;
}

.selected .navStrokeBottom {
	transform: rotate(-45deg);
	transform-origin: left center;
}



@media (max-width: 1160px) {
	.navElement {
		display: none;
	}

	.mobileNav {
		display: inline-block;
		float: right;
	}
}