.Button {
	border-bottom: 2px solid #000;
	color: #000;
    padding-bottom: 3px;
    transition: all .1s ease-in-out;
	//margin-right: 20px
}

.Button:hover {
	width: fit-content;
    padding-bottom: 0px;
}