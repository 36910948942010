* {
  font-family: effra, sans-serif;
  font-weight: 400;
  font-style: normal;
}

a {
  text-decoration: none;
  color: #000;
}

.App {
  min-height: 100vh;
  width: 60vw;
  margin-left: 20vw;
}

h1 {
  font-size: 4em;
  font-weight: 500;
  margin-bottom: 0;
}

h2 {
  font-size: 1.5em;
  font-weight: 400;
}

p {
  font-size: 1.3em;
  font-weight: 500;
  line-height: 1.5;
}

pre p {
  font-weight: 400;
}

ul {
  margin-top: 0;
}

li {
  font-size: 1.3em;
  line-height: 1.5;
}

@media (max-width: 950px) {
  .App {
    min-height: 100vh;
    width: 80vw;
    margin-left: 10vw;
  }
}

@media (max-width: 512px) {
  h1 {
    font-size: 3em;
  }
}

@media (max-width: 400px) {
  h1 {
    font-size: 1.5em;
  }
}